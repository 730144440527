@import 'assets/scss/index.scss';

.daysWrapper {
    display: flex;
    gap: var(--exo-spacing-standard);
    margin-bottom: var(--exo-spacing-medium);

    &_day {
        @include display($display: flex, $align-items: flex-end);
        gap: var(--exo-spacing-x-small);
    }
}

.daysBlock_title {
    @include font($size: var(--exo-font-size-small), $weight: var(--exo-font-weight-semi-bold), $color: var(--exo-color-font));
    margin-top: var(--exo-spacing-standard);
}
