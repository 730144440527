@import 'assets/scss/index.scss';

.header {
    &-wrapper {
        background: var(--exo-color-body);
        height: calc(var(--exo-spacing-2x-large) + var(--exo-spacing-x-small));
        display: flex;
        align-items: center;
        position: fixed;
        width: 100vw;
        z-index: 99999;

        > div {
            width: 100%;
        }

        img {
            max-height: 50px;
            max-width: 200px;
        }
    }

    &-mobile-menu-wrapper {
        color: var(--exo-color-body);
        display: flex;
        font-size: 25px;
        justify-content: flex-end;
        align-items: center;
        height: 100%;

        .nav-drawer-wrapper {
            display: flex;
            position: fixed;
            top: 0;
            left: 0;
            height: 100vh;
            overflow: hidden;

            .nav-drawer {
                height: 100vh;
                display: flex;
                overflow: hidden;
            }

            &_hidden {
                display: none;
            }
        }

        @include exoTablet {
            display: none;
        }

        @include exoDesktop {
            display: none;
        }
    }

    &-right {
        height: 100%;
    }

    &__nav {
        display: flex;
        justify-content: flex-end;
        margin-right: -12px;
        height: 100%;
        align-items: center;
        padding: var(--exo-spacing-large);

        @include exoMobile {
            display: none;
        }
    }

    &__link {
        color: var(--exo-color-font-inverse);
        font-size: var(--exo-font-size-small);
        font-weight: var(--exo-font-weight-semi-bold);
        line-height: var(--exo-line-height-body);

        &:hover {
            cursor: pointer;
            text-decoration: none;
        }

        &.active {
            text-decoration: none;
        }
    }

    &-logo {
        display: flex;
        align-items: center;
        height: 100%;

        a {
            margin-top: calc(var(--exo-spacing-x-large) + var(--exo-spacing-x-small));
            height: 50px;
            width: 100%;
        }
    }
}
