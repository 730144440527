@import 'assets/scss/index.scss';

.scheduleBlock_subtitle {
    @include font($size: var(--exo-font-size-small), $weight: var(--exo-font-weight-semi-bold), $color: var(--exo-color-font));
    margin-top: var(--exo-spacing-standard);
}

.scheduleBlock_description {
    @include font($size: var(--exo-font-size-micro), $weight: var(--exo-font-weight-regular), $color: var(--exo-color-font-disabled));
    margin-bottom: var(--exo-spacing-medium);
}

.scheduleBlock_advancedSubtitle,
.scheduleBlock_advancedDescription,
.scheduleBlock_advancedSchedule {
    margin-bottom: var(--exo-spacing-medium);
}

.scheduleBlock_advancedDescription,
.scheduleBlock_advancedSchedule {
    @include font($size: var(--exo-font-size-small), $weight: var(--exo-font-weight-semi-bold), $color: var(--exo-color-font));
}

.scheduleBlock_advancedSchedule {
    line-height: var(--exo-line-height-heading);
    padding-left: var(--exo-spacing-medium);
}

.dropdownWidth{
    width: 208px;
}

.runEvery {
    gap: var(--exo-spacing-standard);
    width: 208px;
    &_error {
        border: 1px solid $btn-danger;
    }
}

.runFrom {
    @include display($display: flex);
    @include font($size: var(--exo-font-size-small), $weight: var(--exo-font-weight-semi-bold), $color: var(--exo-color-font));
    gap: var(--exo-spacing-standard);
    width: 210px;
    &_to {
        margin-top: var(--exo-spacing-3x-small);
        color: var(--exo-color-font);
    }
}

.deleteWrapper {
    @include display($display: flex, $align-items: center);
    cursor: pointer;
    gap: var(--exo-spacing-x-small);

    span {
        margin-top: var(--exo-spacing-3x-small);
    }
}

.errorMessage {
    @include font($size: var(--exo-font-size-micro), $weight: var(--exo-font-weight-regular));
    color: $btn-danger;
    margin-top: var(--exo-spacing-medium);
    margin-bottom: var(--exo-spacing-medium);
}
.dropDownLabel{
    font-weight:lighter;
}
.disabledDropdownWrapper {
    pointer-events: none;
    filter: grayscale(100%) brightness(90%);
}

ex-select::part(select-menu-popup) {
    z-index: 1000000;
}

.scheduleBlock_interval {
    margin-top: var(--exo-spacing-2x-small);
}
