@import 'assets/scss/index.scss';

.subtitleWithIcon {
    @include display($display: flex, $align-items: flex-start);
    gap: var(--exo-spacing-x-small);
    margin-bottom: var(--exo-spacing-x-small);
    &_icon {
        font-size: var(--exo-font-size-3x-large);
    }
}

.processDescription {
    @include font($size: var(--exo-font-size-small), $weight: var(--exo-font-weight-regular), $lineHeight: var(--exo-line-height-body));
    max-width: 480px;
    margin-bottom: var(--exo-spacing-standard);
    color: var(--exo-color-font);
}

.searchBar {
    width: 68%;
    margin-bottom: 30px;
}

.alertContainer {
    margin-bottom: var(--exo-spacing-medium);
    &Title{
        font: var(--exo-text-label-medium-bold);
        color: var(--exo-color-font-link-secondary);
    }
}

.btn {
    border-top: 1px solid $border-color-light;

    &Wrapper {
        display: flex;
        max-width: 160px;
        margin-top: 35px;
    }
}
.sub_title{
    margin-top: var(--exo-spacing-medium);
}
